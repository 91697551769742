@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Regular.ttf') format('truetype');
  /* Add additional src declarations for different font file formats if needed */
}

body {
  background-color: #1a1a1a; /* Dark gray background */
  color: #ffffff; /* White text color */
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

h1 {
  font-size: 24px; /* Adjust font size */
  margin-bottom: 20px; /* Add some space below the heading */
  color: #ffffff; /* White text color */
  text-align: center; /* Center align the heading */
}

.content-container {
  padding: 10px;
}

.recording {
  display: flex;
  margin-bottom: 20px;
  background-color: #272727;
  border-radius: 10px;
  overflow: hidden;
}

.thumbnail-container {
  display: flex;
  justify-content: center; /* Horizontally center the thumbnails */
  position: relative;
  /* margin-bottom: 10px; /* Adjust margin as needed */
}

.thumbnail-img {
  /* height: 96px;*/
  border-right: 1px solid #444;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 25%;
  
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  font-size: 24px;
  cursor: pointer;
}

.recording-info {
  padding: 10px;
  margin-left: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recording-info h3 {
  margin: 0 0 10px;
  font-size: 18px;
}

.recording-info p {
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
}

.recording-info a {
  color: #4CAF50;
  text-decoration: none;
  font-size: 14px;
}

.recording-info a:hover {
  text-decoration: underline;
}

.video-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 98%;
  max-height: 80%;
  
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  z-index: 50; /* Ensure the video is on top of everything */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container video {
  max-width: 100%;
  max-height: 100%;
}

.video-container .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 48px;
  cursor: pointer;
  z-index: 55; /* Ensure the close button is on top of the video */
}

#loading-spinner,
.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light gray border */
  border-top: 4px solid #ffffff; /* White border on top */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Spin animation */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Live Video Styles*/
#live-video {
  width: 100%;
  height: auto;
}


/* Burger menu styles */
.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #00BAF0;
  background: linear-gradient(to left, #f46b45, #eea849);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #FFF;
  height: 24px;
  padding: 1em;
  z-index: 100;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}


  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 100;
  }
  .menu > li {
    display: flex;
    justify-content: right;
    margin: 0;
    padding: 0.5em 0;
    padding-right: 30px;
    width: 100%;
    color: white;
    background-color: #222;
  }

  .menu > li > a {
      display: flex;
      justify-content: right;
      margin: 0;
      padding: 0.5em 0;
      padding-right: 30px;
      width: 100%;
      color: white;
      background-color: #222;
      text-decoration: none;
    }

  .menu > li > a:hover {
      font-weight: bolder;
    }


  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }



/* Styles for the rocket image */
.rocket {
  width: 64px;
  height: 64px;
  background-image: url('rocket.svg');
  background-size: contain;
  background-repeat: no-repeat;
  animation: rocketAnimation 1s linear infinite; 
  }

/* Keyframes for randomized rocket trembling animation */
@keyframes rocketAnimation {
0% {
  transform: translateY(0) rotateZ(0deg);
}
5% {
  transform: translateY(-5px) rotateZ(-4deg);
}
10% {
  transform: translateY(2px) rotateZ(2deg);
}
15% {
  transform: translateY(-3px) rotateZ(-3deg);
}
20% {
  transform: translateY(3px) rotateZ(-1deg);
}
25% {
  transform: translateY(-1px) rotateZ(3deg);
}
30% {
  transform: translateY(4px) rotateZ(-2deg);
}
35% {
  transform: translateY(-2px) rotateZ(1deg);
}
40% {
  transform: translateY(1px) rotateZ(-3deg);
}
45% {
  transform: translateY(-4px) rotateZ(4deg);
}
50% {
  transform: translateY(0) rotateZ(0deg);
}
55% {
  transform: translateY(-2px) rotateZ(-1deg);
}
60% {
  transform: translateY(3px) rotateZ(3deg);
}
65% {
  transform: translateY(-4px) rotateZ(2deg);
}
70% {
  transform: translateY(2px) rotateZ(-4deg);
}
75% {
  transform: translateY(5px) rotateZ(1deg);
}
80% {
  transform: translateY(-3px) rotateZ(0deg);
}
85% {
  transform: translateY(1px) rotateZ(3deg);
}
90% {
  transform: translateY(-4px) rotateZ(-2deg);
}
95% {
  transform: translateY(3px) rotateZ(-3deg);
}
100% {
  transform: translateY(0) rotateZ(0deg);
}
}


 /* Styles for the loading container */
.loading-container {
  position: absolute; /* or 'absolute' if you want it relative to a container */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* bottom: 0;*/
  width: 100%; /* To span the entire width of the viewport */
  background-color: #3f3f3f; /* Just for visibility, you can change this */
  /* padding: 10px; /* Adjust padding as needed */
  text-align: center; /* Center the content horizontally */
}

/* Styles for the loading text */
.loading-text {
  font-size: 20px;
  /*font-family: Arial, sans-serif;*/
  color: #ffffff;
  /*margin-top: 20px;*/
}


.notifications-container {
  margin: 20px;
}

.description {
  margin-bottom: 20px;
}

.notification-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
}

input[type="email"],
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  background-color: #f18947;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #f34f22;
}


